<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Gateways de pagamento</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Gateways de
                  <span id="txtDashboardNomePlataforma">pagamento</span>
                </h1>
                <p class="aluno_font_color my-1">Configure os gateways de pagamento disponíveis na plataforma!</p>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div v-if="!$store.state.fastCarregando && fastGatewaysPagamentoArray.length" class="col-12 fd-app-jobs">
                  <div class="row col-sm-12 col-md-12 col-lg-4 mt-4 mb-3">
                    <h4 class="aluno_font_color text-nowrap">
                      Lista de gateways suportados ({{fastGatewaysPagamentoArray.length}})
                    </h4>
                  </div>                    
                  <div v-for="g in this.fastGatewaysPagamentoArray" :key="g.id_pagamento_gateway">
                    <div class="card mb-4">
                      <div class="card-header text-center text-wrap">
                        <h4 class="pt-1">{{ g.nome_gateway }}</h4>
                      </div>
                      <div class="card-body pb-0">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="text-center">
                            <span v-if="g.disponivel" class="btn-novo btn-success text-white"><small>Ativado</small></span>
                            <span v-else class="btn-novo btn-dark text-white"><small>Desativado</small></span>
                          </div>
                          <div>
                            <div class="form-group">
                              <label for="nome-gateway">Gateway de pagamento:</label>  
                              <input type="text" id="nome-gateway" class="form-control" :value=g.nome_gateway readonly>  
                            </div>
                            <div class="form-group">
                              <label for="descricao-gateway">Descrição:</label>  
                              <input type="text" id="descricao-gateway" class="form-control mx-auto" :value=g.descricao_gateway readonly>  
                            </div>
                          </div>
                          <div v-if="g.disponivel">
                            <div v-if="g.client_id_obrigatorio" class="form-group">
                              <label for="client-id-gateway">CLIENT ID:</label>  
                              <input v-if="g.usando_config_padrao" id="client-id-gateway" type="text" class="form-control mx-auto" v-model=g.client_id_padrao readonly>
                              <input v-else type="text" id="client-id-gateway" class="form-control mx-auto" v-model="g.client_id" readonly>
                            </div>
                            <div v-if="g.client_secret_obrigatorio" class="form-group">
                              <label for="client-secret-gateway">CLIENT SECRET:</label>  
                                <input v-if="g.usando_config_padrao" type="text" id="client-secret-gateway" class="form-control mx-auto" v-model=g.client_secret_padrao readonly>
                                <input v-else type="text" id="client-secret-gateway" class="form-control mx-auto" v-model="g.client_secret" readonly>
                            </div>
                          </div>
                          <div v-else class="text-center my-3">
                            <span class="text-danger">
                              Este gateway de assinaturas ainda não está disponível para uso.
                              Configue para ativar.
                            </span>
                          </div>
                          <div v-if="g.recursos.filter(i => i.suportado).length" class="d-flex flex-column">
                            <label>RECURSOS DISPONÍVEIS:</label>
                            <div class="row d-flex col-12 pr-0">
                              <div v-for="recurso in g.recursos.filter(i => i.suportado)" :key="recurso.forma_pagamento" class="p-1 border rounded card-pagamento">
                                <div class="w-100 d-flex justify-content-center">
                                  <h6 class="my-1">{{ recurso.forma_pagamento.toUpperCase() }}</h6>
                                </div>
                                <ul class="list-unstyled">
                                  <li v-for="recurso_detalhe in recurso.recursos_descricao" :key="recurso_detalhe.recurso_nome">
                                    <BIconCheckLg v-if="recurso_detalhe.recurso_disponivel" class="icon-pagamento text-success" /> 
                                    <BIconXLg v-else class="icon-pagamento text-danger"/>
                                    <span class="ml-2">{{ recurso_detalhe.recurso_nome }}</span>
                                  </li>
                                  <div v-if="recurso.forma_pagamento === 'Link de pagamento' ">
                                    <span class="text-secondary">Modalidades aceitas:</span>
                                    <li v-for="forma_pgto in recurso.formas_pagamento.filter(i => i.suportado)" :key="forma_pgto.forma_pagamento">
                                      - {{ forma_pgto.forma_pagamento }}
                                    </li>
                                    <li v-if="!recurso.formas_pagamento.length">
                                      Sem resultados
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </div>  
                          </div>
                          <div v-else class="w-100 d-flex justify-content-center">
                            Não há recursos listados
                          </div>
                          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
                            <a class="btn-novo btn-primary text-white text-nowrap my-1" @click="exibeModalConfigurarGateway(g)"><small>Configurar Gateway</small></a>
                            <a v-if="g.disponivel && !g.usando_config_padrao && g.webhook_configuravel && !g.webhook_configurado" class="btn-novo btn-danger text-white text-nowrap my-1 h5 blink_me" @click="exibeModalConfigurarWebhook(g)"><small>Configurar Webhook</small></a>
                            <a v-else-if="g.disponivel && !g.usando_config_padrao && g.webhook_configuravel" class="btn-novo btn-info text-white text-nowrap my-1" @click="exibeModalConfigurarWebhook(g)"><small>Configurar Webhook</small></a>
                            <a v-if="g.disponivel" class="btn-novo btn-secondary text-white text-nowrap my-1" @click="exibeModalVerificarConexao(g)"><small>Verificar conexão</small></a>
                            <a v-if="g.disponivel" class="btn-novo btn-info text-white text-nowrap my-1" @click="exibeModalLogs(g.id_pagamento_gateway)"><small>Exibir logs</small></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="d-flex justify-content-center text-center my-5">
                    <b-icon icon="gear-fill" animation="spin" /> Carregando
                  </div>
                </div>
              </div>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <modal
      name="modalConfigurarGateway"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Configurar Gateway {{ fastGatewaysPagamento.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalConfigurarGateway') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3">
          <div v-if="fastGatewaysPagamento.informacoes_tecnicas" class="my-2">
            <h6>
              {{fastGatewaysPagamento.informacoes_tecnicas}}
            </h6>
          </div>
          <div v-if="fastGatewaysPagamento.client_id_obrigatorio" class="my-2">
            <label for="input-client-id">CLIENT ID:</label>  
            <input type="text" id="input-client-id" class="form-control" v-model="fastGatewaysPagamento.client_id">
          </div>
          <div v-if="fastGatewaysPagamento.client_secret_obrigatorio" class="my-2">
            <label for="input-client-secret">CLIENT SECRET:</label>  
            <input type="text" id="input-client-secret" class="form-control" v-model="fastGatewaysPagamento.client_secret">
          </div>
          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
            <a v-if="fastGatewaysPagamento.url_documentacao" class="btn-novo btn-primary text-white text-nowrap my-1" :href=fastGatewaysPagamento.url_documentacao target="_blank"><small>Informações Técnicas</small></a>
            <a class="btn-novo btn-success text-white text-nowrap my-1" @click="postAtualizaDadosGateway()"><small>Verificar e salvar</small></a>
            <a class="btn-novo btn-danger text-white text-nowrap my-1" @click="exibeModalRestaurarConfiguracoes()"><small>Restaurar configurações</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalRestaurarConfiguracoes"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthMd"
    >
      <div v-if="$store.state.fastCarregando" class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Restaurar configurações de {{ fastGatewaysPagamento.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalRestaurarConfiguracoes')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          Reseja realmente restaurar as configurações originais deste gateway de assinatura? As configurações atuais serão perdidas.
          <div class="my-2">
            <a class="btn-novo btn-danger text-white text-nowrap" @click="postRestauraPadraoGateway()"><small>Restaurar configurações</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfigurarWebhook"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Configurar Webhook {{ fastGatewaysPagamento.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalConfigurarWebhook')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div v-if="fastGatewaysPagamento.webhook_informacoes_tecnicas" class="my-2">
            <h6>
              {{fastGatewaysPagamento.webhook_informacoes_tecnicas}}
            </h6>
          </div>
          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
            <a v-if="fastGatewaysPagamento.webhook_url_documentacao" class="btn-novo btn-primary text-white text-nowrap my-1" :href=fastGatewaysPagamento.webhook_url_documentacao target="_blank"><small>Informações Técnicas</small></a>
            <a class="btn-novo btn-success text-white text-nowrap my-1" @click="exibeModalGerarWebhook()"><small>Gerar URL de Webhook</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalGerarWebhook"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthLg"
    >
      <div v-if="$store.state.fastCarregando" class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Gerar token de webhook para {{ fastGatewaysPagamento.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalGerarWebhook'), getGatewaysPagamento()">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <p>
            <span class="btn-novo btn-warning">Atenção</span>
          </p>
          <p>
            Para que você possa concluir a configuração deste gateway de assinaturas é necessário gerar uma URL de Webhook e cadastrar na plataforma do gateway conforme instruções técnicas na respectiva documentação. Mas atenção, esse link é gerado apenas uma vez, portanto, salve caso precise futuramente. 
            O FastEAD não armazena essa informação, caso você clique para gerar novamente, será gerado um novo link e a configuração terá de ser refeita.
          </p>
          <div class="my-2">
            <a class="btn-novo btn-success text-white text-nowrap" @click="postGeraTokenWeb()"><small>Gerar URL de Webhook</small></a>
          </div>
        </div>
        <div v-if="fastTokenWebhook" class="p-3">
          <div v-if="!$store.state.fastCarregando" class="d-flex justify-content-center">
            <div class="input-group mb-3">
              <input 
                type="text" 
                id="url-webhook" 
                class="form-control" 
                v-model="fastTokenWebhook" 
                v-on:focus="$event.target.select()" 
                ref="urlwebhook" 
                readonly>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-copy" type="button" id="btn-copy" @click.prevent="copiaURLWebhook()"><b-icon-file-text/></button>
              </div>
            </div>        
          </div>
          <div v-else class="row">
            <div class="d-flex justify-content-center text-center my-5">
              <b-icon icon="gear-fill" animation="spin" /> Carregando
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalVerificarConexao"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Verificar alterações de {{ fastGatewaysPagamento.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalVerificarConexao')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div v-if="!this.$store.state.fastCarregando && fastGatewayConexao === 'sucesso'" class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div>
            <span class="btn-novo btn-success text-white">Sucesso</span>
          </div>
          <div>
            As credenciais são válidas, conexão verificada com sucesso.
          </div>
        </div>
        <div v-else-if="!this.$store.state.fastCarregando && fastGatewayConexao === 'erro'" class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div>
            <span class="btn-novo btn-danger text-white">Erro</span>
          </div>
          <div>
            Erro na conexão, verifique os dados e tente novamente.
          </div>
        </div>
        <div v-else class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalLogs" :shift-y="0.1" height="auto" :width="modalWidthLg" :scrollable="true"
      :adaptative="true">
      <div class="row fd-app-table-novo-logs m-pers-modal-jobs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Logs</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalLogs')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row mt-4">
            <transition name="slide-fade">
              <div
                class="col-12"
              >                  
                <div class="card mb-4">
                  <div class="card-header py-1">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="col-sm-12 col-md-12 col-lg-12 flex p-0">
                          <div class="d-flex align-items-center text-nowrap">
                            <div class="col-sm-3 col-md-3 col-lg-3 p-0">
                              <label for="log_sucesso" class="text-secondary mb-0"><small>Status:</small></label>
                              <select name="log_sucesso" id="log_sucesso" class="select-size form-control form-control-sm" 
                                v-model="fastFiltrosLogsGatewayPagamento.sucesso">
                                <option value="">Todos</option>
                                <option value="true">Sucesso</option>
                                <option value="false">Falha</option>
                              </select>
                            </div>
                          </div>
                          <b-row align-h="between">
                            <div class="text-left col-lg-9 col-md-9 col-sm-9">
                              <label class="text-secondary mb-0 mx-0 row">
                                <small class="pl-0 ml-0 pl-0 col-8">Filtrar por data:</small>
                                <small
                                class="col-4 text-right mr-0 pr-0"
                                role="button"
                                v-if="fastFiltrosLogsGatewayPagamento.dataInicio || fastFiltrosLogsGatewayPagamento.dataFinal" 
                                @click="fastFiltrosLogsGatewayPagamento.dataInicio = '', fastFiltrosLogsGatewayPagamento.dataFinal = ''"
                                >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                              </label>
                              <div class="d-flex">
                                <b-form-datepicker 
                                class="align-self-start"
                                size="sm"
                                locale="pt-BR"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                label-no-date-selected="Selecionar data"
                                placeholder="Selecionar data"
                                label-help="Use o cursor para selecionar"
                                v-model="fastFiltrosLogsGatewayPagamento.dataInicio"
                                ></b-form-datepicker>
                                <small class="align-self-start pt-1 px-2">até</small>
                                <b-form-datepicker 
                                class="align-self-start"
                                size="sm"
                                locale="pt-BR"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                label-no-date-selected="Selecionar data"
                                placeholder="Selecionar data"
                                label-help="Use o cursor para selecionar"
                                v-model="fastFiltrosLogsGatewayPagamento.dataFinal"
                                ></b-form-datepicker>
                              </div>              
                            </div>
                            <div class="col-lg-2 col-md-2 co2-sm-2 mt-3 ml-3 pt-1 text-nowrap">
                              <a class="btn-novo btn-primary text-white font-weight-light px-2" 
                              @click.prevent="getLogsFiltrados()"
                              >Pesquisar</a>
                            </div>
                            <div class="ml-auto mt-3 text-right d-flex justify-content-end align-items-center">
                              <a
                                class="text-success fast-cursor-refresh"
                                @click.prevent="getLogsSemFiltros()"
                              >
                                <b-icon-arrow-counterclockwise font-scale="2" />
                              </a>
                            </div>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-1">
                    <div v-if="fastLogsGatewayPagamento.data.length">
                      Total de resultados: {{ fastLogsGatewayPagamento.total_results }}. Exibindo do {{ fastLogsGatewayPagamento.range_start }} ao {{ fastLogsGatewayPagamento.range_end }}.
                    </div>
                    <div class="row">
                      <div class="col-12 table-responsive mt-2">
                        <table class="table table-sm text-nowrap">
                          <thead class="thead-dark">
                            <tr>
                              <th class="text-center">
                                <small class="font-weight-bold">Nome do Evento</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Data do Evento</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Status</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Detalhes</small>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="fastLoadingLogsGatewayPagamento">
                            <tr>
                              <td colspan="5">
                                <div class="col-12 text-center">
                                  <div class="align-self-center mx-auto text-center">            
                                    <h4>
                                      <b-icon
                                        icon="gear-fill"
                                        animation="spin"
                                      /> Carregando
                                    </h4>
                                  </div>
                                </div> 
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="fastLogsGatewayPagamento.data.length">
                            <tr v-for="log in fastLogsGatewayPagamento.data" :key="log.id_pagamento_gateway_plataforma_log"
                            >
                              <td class="align-middle text-center">
                                <small>
                                  {{ log.nome_evento }}
                                </small>
                              </td>
                              <td class="align-middle text-center">
                                <small>
                                  {{new Date(log.data_evento).toLocaleString("pt-BR") }}
                                </small>
                              </td>
                              <td class="align-middle text-center">
                                <small
                                  v-if="log.sucesso"
                                  class="btn btn-sm btn-success pt-0 pb-0 text-lowercase"
                                  >Sucesso</small>
                                  <small
                                  v-else
                                  class="btn btn-sm btn-danger pt-0 pb-0 text-lowercase"
                                  >Falha</small>
                              </td>
                              <td class="align-middle text-center">
                                <a class="btn-novo btn-primary text-white font-weight-light px-2" 
                                :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                                  @click.prevent="exibeModalDetalhesLog(log.id_transacao)">
                                  <small>
                                    Mais detalhes
                                  </small>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                colspan="6"
                                class="text-center"
                              >
                                Não foram encontrados logs.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="align-self-center mx-auto text-center">
                        <b-pagination class="mt-1"
                          v-model="fastFiltrosLogsGatewayPagamento.pageNumber"
                          :total-rows="fastLogsGatewayPagamento.total_results"
                          :per-page="fastFiltrosLogsGatewayPagamento.pageSize"
                          @change="getLogs()"

                          ></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>                  
              </div>
            </transition>
          </div>
        </div>
      </div>
    </modal>
    <modal name="modalDetalhesLog" :shift-y="0.1" height="auto" :width="modalWidthLg" :scrollable="true"
      :adaptative="true">
      <div class="row fd-app-table-novo-logs m-pers-modal-jobs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Logs da transação: {{ fastLogsGatewayPagamento.data.id_transacao }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="fechaModalDetalhesLog()">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div>
            <div v-if="fastLoadingLogsGatewayPagamento">
              <div class="row">
                <div class="align-self-center mx-auto text-center">            
                  <h4>
                    <b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando logs
                  </h4>
                </div>
              </div>
            </div>
            <div
              class="col-12 py-2"
              v-else
              v-for="log_detail in fastLogsGatewayPagamento.data" :key="log_detail.id_pagamento_gateway_plataforma_log"
            >                  
              <div class="card shadow p-2">
                <p>
                  #{{ log_detail.id_pagamento_gateway_plataforma_log }} - {{ log_detail.nome_evento }}
                </p>
                <p>
                  {{ new Date(log_detail.data_evento).toLocaleString('pt-BR') }}
                </p>
                <p>
                  {{ log_detail.descricao_evento }}
                </p>
                <p v-if="log_detail.sucesso" class="text-success">
                  Sucesso
                </p>
                <p v-else class="text-danger">
                  Falha
                </p>
                <p>
                  {{ log_detail.data }}
                </p>
              </div>                  
            </div>
          </div>
        </div>
      </div>
    </modal>
    
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import "cropperjs/dist/cropper.css";
import { BIconXLg, BIconCheckLg } from "bootstrap-vue";

export default {
  name: "HomeInternoAdminIntegracoesGatewaysPagamento",
  components: { BIconXLg, BIconCheckLg },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "60%" : "50%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastGatewaysPagamentoArray: [],
      fastGatewaysPagamento: {
        id_pagamento_gateway: 0,
        nome_gateway: "",
        codigo_gateway: "",
        descricao_gateway: "",
        padrao: false,
        config_obrigatorio: false,
        client_id_obrigatorio: false,
        client_secret_obrigatorio: false,
        url_documentacao: "",
        informacoes_tecnicas: "",
        client_id: "",
        client_secret: "",
        disponivel: false,
        usando_config_padrao: false,
        client_id_padrao: null,
        client_secret_padrao: null,
        url_api_padrao: "",
        webhook_configuravel: false,
        webhook_configurado: false,
        webhook_secret_key: null,
        webhook_secret_key_padrao: "●",
        webhook_url_documentacao: "",
        webhook_informacoes_tecnicas: "",
        webhook_endpoint: "",
        webhook_param_padrao: "",
        env_dev: true,
        class_name: ""
      },
      fastGatewayConexao: "",
      fastTokenWebhook: "",
      fastLogsGatewayPagamento: {
        data: [],
        success: true,
        range_start: 0,
        range_end: 0,
        total_results: 0,
        error: null,
        description: null,
      },
      fastLogsGatewayPagamentoDetalhes: {
        id_pagamento_gateway_plataforma_log: 0,
        id_transacao: "",
        id_pagamento_gateway: 0,
        id_plataforma: 0,
        data_evento: "",
        ano: 0,
        mes: 0,
        dia: 0,
        nome_evento: "",
        descricao_evento: "",
        data: "",
        sucesso: false
      },
      fastFiltrosLogsGatewayPagamento: {
        id_transacao: "",
        dataInicio: "",
        dataFinal: "",
        sucesso: '',
        pageNumber: 1,
        pageSize: 10,
        id_pagamento_gateway: 0,
      },
      fastLoadingLogsGatewayPagamento: true,
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.$store.state.fastCarregando = true;
          this.getGatewaysPagamento();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          console.log(e)
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getGatewaysPagamento () {
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_pagamento_gateway/lista_disponiveis_admin_com_recursos", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then(res => {
        if (res.success === false) {
          console.log(res.error)
          console.log(res.description)
          this.exibeToasty(res.error + " " + res.description, "error")          
          this.$store.state.fastCarregando = false;
        } else {
          if (res.length) {
            // console.log('testederes', res);
            this.$store.state.fastCarregando = false;
            this.fastGatewaysPagamentoArray = res;
            this.$store.state.fastCarregando = false;
          }
        }
      }).catch(e => {
        console.log(e);
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      })
    },
    getVerificaConexaoGatewayAssinatura () {
      this.$store.state.fastCarregando = true;
      this.fastGatewayConexao = "aguardando";
      this.promiseGetFastApi("api/fast_pagamento_gateway/check_connection", `id_plataforma=${this.$route.params.id_plataforma}&id_pagamento_gateway=${this.fastGatewaysPagamento.id_pagamento_gateway}`)
      .then(res => {
        this.$store.state.fastCarregando = true;
        if (res) {
          if (res.success) {
            this.fastGatewayConexao = "sucesso"
            this.$store.state.fastCarregando = false;
          } else if (!res.success) {
            this.fastGatewayConexao = "erro"
            console.log(res.error)
            console.log(res.description)
            this.exibeToasty(res.error + " " + res.description, "error")
            this.$store.state.fastCarregando = false;
          }
        } else {
          this.exibeToasty("Erro ao verificar conexão", "error")
          this.$store.state.fastCarregando = false;
        }
      }).catch(e => {
        console.log(e);
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      })
    },
    postAtualizaDadosGateway(){
      this.$store.state.fastCarregando = true;
      let obj = { 
        id_pagamento_gateway: this.fastGatewaysPagamento.id_pagamento_gateway,
        id_plataforma: this.$route.params.id_plataforma,
        client_id: this.fastGatewaysPagamento.client_id ? this.fastGatewaysPagamento.client_id : null,
        client_secret: this.fastGatewaysPagamento.client_secret ? this.fastGatewaysPagamento.client_secret : null
      }
      this.promisePostFastApi(obj, "api/fast_pagamento_gateway/atualiza")
          .then(res => {
            if (res.success) {
              this.exibeToasty("Alterado com sucesso", "success")
              this.hideModal('modalConfigurarGateway')
              this.getGatewaysPagamento();
              this.$store.state.fastCarregando = false;
            } else {
              console.log(res.error)
              console.log(res.description)    
              this.exibeToasty(res.error + " " + res.description, "error")
              this.$store.state.fastCarregando = false;   
            }
          })
          .catch(e => {
            console.log(e);
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          })
    },
    postRestauraPadraoGateway(){
      this.$store.state.fastCarregando = true;
      let obj = { 
        id_pagamento_gateway: this.fastGatewaysPagamento.id_pagamento_gateway,
        id_plataforma: this.$route.params.id_plataforma,
        voltar_config_padrao: true
      }
      this.promisePostFastApi(obj, "api/fast_pagamento_gateway/atualiza")
          .then(res => {
            console.log(res)
            if (res.success) {
              this.exibeToasty("Alterado com sucesso", "success")
              this.hideModal('modalRestaurarConfiguracoes')
              this.hideModal('modalConfigurarGateway')
              this.getGatewaysPagamento();
              this.$store.state.fastCarregando = false;
            } else {
              console.log(res.error)
              console.log(res.description)    
              this.exibeToasty(res.error + " " + res.description, "error")
              this.$store.state.fastCarregando = false;  
            }
          })
          .catch(e => {
            console.log(e);
            this.exibeToasty(e, "error");
          })
    },
    async postGeraTokenWeb(){
      this.$store.state.fastCarregando = true;
      return await new Promise((resolve, reject) => {
      try {
        let resp = fetch(
          settings.endApiFastEad + `api/fast_pagamento_gateway/gerar_webhook_url?id_plataforma=${this.$route.params.id_plataforma}&id_pagamento_gateway=${this.fastGatewaysPagamento.id_pagamento_gateway}`,
          this.fastAjaxOptions("POST", "")
        )
        let json = resp.json();
        if (json.sucess == false) {
          console.log(json.error)
          console.log(json.description)    
          this.exibeToasty(json.error + " " + json.description, "error")
          resolve(json)
          this.$store.state.fastCarregando = false
        } else {
          // console.log("json teste: " + json.webhook_url)
          this.fastTokenWebhook = json.webhook_url;
          this.exibeToasty("Token gerado com sucesso", "success")
          resolve(json);
          this.getGatewaysPagamento()
          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log(e);
        this.exibeToasty(e, "error");
        reject(e);
      }})
    },
    exibeModalConfigurarGateway(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewaysPagamento = gateway;
      this.showModal('modalConfigurarGateway');
      this.$store.state.fastCarregando = false;
    },
    exibeModalRestaurarConfiguracoes(){
      this.$store.state.fastCarregando = true;
      this.showModal('modalRestaurarConfiguracoes');
      this.$store.state.fastCarregando = false;
    },
    exibeModalConfigurarWebhook(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewaysPagamento = gateway;
      this.showModal('modalConfigurarWebhook');
      this.$store.state.fastCarregando = false;
    },
    exibeModalGerarWebhook(){
      this.$store.state.fastCarregando = true;
      this.fastTokenWebhook = "";
      this.showModal('modalGerarWebhook');
      this.$store.state.fastCarregando = false;
    },
    exibeModalVerificarConexao(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewaysPagamento = gateway;
      this.getVerificaConexaoGatewayAssinatura();
      this.showModal('modalVerificarConexao');
      this.$store.state.fastCarregando = false;
    },
    copiaURLWebhook() {
      this.$refs.urlwebhook.focus();
      document.execCommand('copy');
      this.exibeToasty("URL de Webhook do gateway de assinaturas copiado para área de transferências", "success")
    },
    exibeModalLogs(gateway){
      this.limpaCampos()
      this.fastFiltrosLogsGatewayPagamento.id_pagamento_gateway = gateway
      this.fastFiltrosLogsGatewayPagamento.pageSize = 10
      this.getLogs()
      this.showModal('modalLogs')
    },
    limpaCampos(){
      this.fastFiltrosLogsGatewayPagamento.id_transacao = ''
      this.fastFiltrosLogsGatewayPagamento.sucesso = ''
      this.fastFiltrosLogsGatewayPagamento.dataInicio = ''
      this.fastFiltrosLogsGatewayPagamento.dataFinal = ''
      this.fastFiltrosLogsGatewayPagamento.pageNumber = 1
    },
    getLogsSemFiltros(){
      this.limpaCampos()
      this.getLogs()
    },
    getLogsFiltrados(){
      this.fastFiltrosLogsGatewayPagamento.pageNumber = 1
      this.getLogs()
    },
    exibeModalDetalhesLog(id_transacao) {
      this.hideModal('modalLogs')
      this.showModal('modalDetalhesLog')
      this.limpaCampos()
      this.fastFiltrosLogsGatewayPagamento.id_transacao = id_transacao
      this.fastFiltrosLogsGatewayPagamento.pageSize = 100
      this.getLogs()
    },
    fechaModalDetalhesLog(){
      this.hideModal('modalDetalhesLog')
      this.limpaCampos()
      let id_gateway = this.fastFiltrosLogsGatewayPagamento.id_pagamento_gateway
      this.exibeModalLogs(id_gateway)
    },
    getLogs(){
      this.fastLoadingLogsGatewayPagamento = true;
      let data_inicial = this.fastFiltrosLogsGatewayPagamento.dataInicio ? '&data_inicio=' + this.fastFiltrosLogsGatewayPagamento.dataInicio + 'T00:01' : ''
      let data_final = this.fastFiltrosLogsGatewayPagamento.dataFinal ? '&data_fim=' + this.fastFiltrosLogsGatewayPagamento.dataFinal + 'T23:59' : ''
      
      this.$nextTick(() => {
        try {
          this.promiseGetFastApi(
            "api/fast_pagamento_gateway_plataforma_log/lista_logs",
            `id_plataforma=${this.$route.params.id_plataforma}&id_pagamento_gateway=${this.fastFiltrosLogsGatewayPagamento.id_pagamento_gateway}${this.fastFiltrosLogsGatewayPagamento.id_transacao ? '&id_transacao=' + this.fastFiltrosLogsGatewayPagamento.id_transacao : ''}${data_inicial}${data_final}${this.fastFiltrosLogsGatewayPagamento.sucesso ? '&sucesso=' + this.fastFiltrosLogsGatewayPagamento.sucesso : ''}${this.fastFiltrosLogsGatewayPagamento.pageNumber ? '&page_number=' + this.fastFiltrosLogsGatewayPagamento.pageNumber : ''}${this.fastFiltrosLogsGatewayPagamento.pageSize ? '&page_size=' + this.fastFiltrosLogsGatewayPagamento.pageSize : ''}`
          ).then((res) => {
            if (res.success === false || res.error) {
              console.error(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`);
              this.exibeToasty(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`, "error");
            } else {
              // console.log("res",res);
              this.fastLogsGatewayPagamento = res
            }
          }).then(e => {
            this.fastLoadingLogsGatewayPagamento = false;
            // console.log(e)
          })
        } catch (error) {
          console.error(error);
          this.exibeToasty("Ocorreu um erro", "error");
          this.fastLoadingLogsGatewayPagamento = false;
        }
    })
    },
  },
};
</script>

<style scoped>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

.card-pagamento {
  margin: .7em;
  max-width: 30%;
}

.icon-pagamento {
  font-size: .7em;
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Tree */
.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
